import React from 'react';
import getCategoryColor from './categoryColors';

const CategoryFilter = ({ categories, selectedCategories, onCategoryChange }) => {
  return (
    <div className="category-filter">
      <button
        className="filter-tag"
        style={{
          backgroundColor: selectedCategories.length === 0 ? "rgba(99, 102, 241, 0.1)" : "rgba(148, 163, 184, 0.1)",
          borderColor: selectedCategories.length === 0 ? "rgba(99, 102, 241, 0.3)" : "rgba(148, 163, 184, 0.3)",
          color: selectedCategories.length === 0 ? "rgb(99, 102, 241)" : "rgb(148, 163, 184)"
        }}
        onClick={() => onCategoryChange([])}
      >
        All
      </button>
      {categories.map((category) => {
        const colors = getCategoryColor(category);
        return (
          <button
            key={category}
            className="filter-tag"
            style={{
              backgroundColor: selectedCategories.includes(category) ? colors.bg : "rgba(148, 163, 184, 0.1)",
              borderColor: selectedCategories.includes(category) ? colors.border : "rgba(148, 163, 184, 0.3)",
              color: selectedCategories.includes(category) ? colors.text : "rgb(148, 163, 184)"
            }}
            onClick={() => {
              if (selectedCategories.includes(category)) {
                onCategoryChange(selectedCategories.filter(c => c !== category));
              } else {
                onCategoryChange([...selectedCategories, category]);
              }
            }}
          >
            {category}
          </button>
        );
      })}
    </div>
  );
};

export default CategoryFilter;