const getCategoryColor = (category) => {
    const colors = {
      "Talks & Education": {
        bg: "rgba(34, 211, 238, 0.1)",
        border: "rgba(34, 211, 238, 0.3)",
        text: "rgb(34, 211, 238)"
      },
      "Sport": {
        bg: "rgba(168, 85, 247, 0.1)",
        border: "rgba(168, 85, 247, 0.3)",
        text: "rgb(168, 85, 247)"
      },
      "Festival": {
        bg: "rgba(99, 102, 241, 0.1)",
        border: "rgba(99, 102, 241, 0.3)",
        text: "rgb(99, 102, 241)"
      },
      "Music": {
        bg: "rgba(244, 114, 182, 0.1)",
        border: "rgba(244, 114, 182, 0.3)",
        text: "rgb(244, 114, 182)"
      },
      "Exhibition": {
        bg: "rgba(34, 197, 94, 0.1)",
        border: "rgba(34, 197, 94, 0.3)",
        text: "rgb(34, 197, 94)"
      },
      "Theater & Dance": {
        bg: "rgba(249, 115, 22, 0.1)",
        border: "rgba(249, 115, 22, 0.3)",
        text: "rgb(249, 115, 22)"
      },
      "Guided Tour": {
        bg: "rgba(234, 179, 8, 0.1)",
        border: "rgba(234, 179, 8, 0.3)",
        text: "rgb(234, 179, 8)"
      },
      "Film": {
        bg: "rgba(14, 165, 233, 0.1)",
        border: "rgba(14, 165, 233, 0.3)",
        text: "rgb(14, 165, 233)"
      },
      "Vacation Camp": {
        bg: "rgba(139, 92, 246, 0.1)",
        border: "rgba(139, 92, 246, 0.3)",
        text: "rgb(139, 92, 246)"
      }
    };
  
    return colors[category] || {
      bg: "rgba(239, 68, 68, 0.1)",
      border: "rgba(239, 68, 68, 0.3)",
      text: "rgb(239, 68, 68)"
    };
  };
  
export default getCategoryColor;