import React from 'react';

const LanguageSwitcher = ({ language, setLanguage }) => (
  <div className="language-switcher">
    {['nl', 'en'].map(lang => (
      <button
        key={lang}
        className={`lang-button ${language === lang ? 'active' : ''}`}
        onClick={() => setLanguage(lang)}
      >
        {lang.toUpperCase()}
      </button>
    ))}
  </div>
);

export default LanguageSwitcher;
