// src/components/EventList.js

import React, { useState, useMemo, useRef, useEffect } from "react";
import EventCard from "./EventCard";
import eventsDataNl from "../data/events.json";
import eventsDataEn from "../data/events_en.json";
import { motion, useScroll } from "framer-motion";
import Fuse from "fuse.js";
import CategoryFilter from "./CategoryFilter";

const EventList = ({ language }) => {
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedCategories, setSelectedCategories] = useState([]);

  const today = new Date().toISOString().split('T')[0];
  const thirtyDaysAhead = new Date(new Date().setMonth(new Date().getMonth() + 1)).toISOString().split('T')[0];

  // Category order
  const categoryOrder = [
    "Music",
    "Theater & Dance",
    "Exhibition",
    "Academic",
    "Film",
    "Guided Tour",
    "Sport",
    "Festival",
    "Market",
    "Talks & Education",
    "Party",
    "Fiets- of wandelroute",
    "Tech",
    "Vacation Camp",
    "Opendeurdag"
  ];

  // Use the correct events data based on language
  const eventsData = language === 'nl' ? eventsDataNl : eventsDataEn;

  // Initialize Fuse.js with the correct data
  const fuse = useMemo(() => {
    return new Fuse(eventsData, {
      keys: ["title", "location", "category", "date"],
      threshold: 0.3, // Adjust based on desired fuzziness
    });
  }, [eventsData]);

  // Filter events based on search query
  const filteredEvents = useMemo(() => {
    let filtered = eventsData;
    
    // Apply search filter
    if (searchQuery) {
      filtered = fuse.search(searchQuery).map(result => result.item);
    }
    
    // Apply category filter
    if (selectedCategories.length > 0) {
      filtered = filtered.filter(event => selectedCategories.includes(event.category));
    }
    
    return filtered;
  }, [searchQuery, selectedCategories, fuse, eventsData]);

  // Filter and group events by date, stopping after 30 days from today
  const eventsByDate = useMemo(() => {
    return filteredEvents.reduce((acc, event) => {
      if (event.cleaned_dates >= today && event.cleaned_dates <= thirtyDaysAhead) {
        const date = event.cleaned_dates;
        if (!acc[date]) acc[date] = [];
        acc[date].push(event);
      }
      return acc;
    }, {});
  }, [filteredEvents, today, thirtyDaysAhead]);

  const dates = Object.keys(eventsByDate).sort();

  // Function to format date based on language
  const formatDate = (date) => {
    const options = {
      weekday: "short",
      day: "numeric",
      month: "short",
      year: "numeric",
    };
    
    if (language === 'nl') {
      return new Date(date).toLocaleDateString("nl-NL", options);
    } else {
      return new Date(date).toLocaleDateString("en-GB", options);
    }
  };

  const dateRefs = useRef({});
  const [activeDateIndex, setActiveDateIndex] = useState(0);

  const [currentDate, setCurrentDate] = useState('');

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY + window.innerHeight / 2;
      let newCurrentDate = '';

      for (const date of dates) {
        const element = dateRefs.current[date];
        if (element && scrollPosition >= element.offsetTop) {
          newCurrentDate = date;
        }
      }

      setCurrentDate(newCurrentDate);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [dates]);

  return (
    <div className="event-list-container">
      <div className="side-date-indicator">
        {currentDate && formatDate(currentDate)}
      </div>

      {/* Search Bar */}
      <div className="search-bar-container">
        <input
          type="text"
          placeholder={language === 'nl' ? "NTGent, Comedy, Angele, Blauwdruk..." : "NTGent, Comedy, Angele, Blauwdruk..."}
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          className="search-input"
        />
      </div>

      <CategoryFilter
        categories={categoryOrder}
        selectedCategories={selectedCategories}
        onCategoryChange={setSelectedCategories}
      />

      {/* Event List */}
      <div className="event-list">
        {dates.length > 0 ? (
          dates.map((date) => (
            <div key={date} className="date-section" ref={el => dateRefs.current[date] = el}>
              <h2 className="date-heading">{formatDate(date)}</h2>
              <div className="events-container">
                {eventsByDate[date]
                  .sort((a, b) => categoryOrder.indexOf(a.category) - categoryOrder.indexOf(b.category))
                  .map((event, idx) => (
                    <EventCard key={idx} event={event} index={idx} />
                  ))}
              </div>
            </div>
          ))
        ) : (
          <p className="no-events-message">
            {language === 'nl' ? 
              "Geen evenementen gevonden." : 
              "No events found."}
          </p>
        )}
      </div>
    </div>
  );
};

export default EventList;
