import React from "react";
import { motion } from "framer-motion";
import { Calendar, MapPin } from "lucide-react";
import getCategoryColor from './categoryColors';

const EventCard = ({ event, index }) => {
  const categoryColors = getCategoryColor(event.category);

  return (
    <motion.a
      href={event.link}
      target="_blank"
      rel="noopener noreferrer"
      className="event-card group"
      whileHover={{ y: -4 }}
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ 
        duration: 0.3,
        delay: index * 0.1,
        ease: [0.23, 1, 0.32, 1]
      }}
    >
      <div className="flex flex-col h-full">
        <div className="mb-4">
          <h3 className="event-title mb-2">{event.title}</h3>
          <div className="flex items-center gap-2 text-white">
            <MapPin size={18} style={{ color: '#6B7280', marginRight: '4px', marginTop: '4px' }}/>
            <span style={{ color: '#6B7280', marginBottom: '10px'}}>{event.location}</span>
          </div>
          <div className="flex items-center gap-2 text-sm mb-2">
            <Calendar size={18} style={{ color: '#6B7280', marginRight: '4px', marginTop: '4px' }}  />
            <span style={{ color: '#6B7280' }}>{event.date}</span>
          </div>
        </div>

        <div className="mt-auto" style={{ marginTop: '20px' }}>
          <span
            className="inline-flex items-center px-3 py-1 rounded-full text-xs font-medium"
            style={{
              backgroundColor: categoryColors.bg,
              borderColor: categoryColors.border,
              color: categoryColors.text,
              border: `1px solid ${categoryColors.border}`,
              borderRadius: '4px',
              padding: '2px 2px',
            }}
          >
            {event.category}
          </span>
        </div>
      </div>
    </motion.a>
  );
};

export default EventCard;
